import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';

const content_spacing = '25px';
const md_content_spacing = '35px';

export const StyledGridContentContainer = styled(StyledContainer)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr;
    
    ${theme.mediaQuerys.smUp} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto 1fr;
        grid-column-gap: 20px;
        align-items: flex-start;
    }

    ${theme.mediaQuerys.mdUp} {
        grid-template-columns: 1.2fr .8fr;
        grid-column-gap: 40px;
    }

    ${theme.mediaQuerys.lgUp} {
        grid-template-columns: 1.15fr .65fr;
    }

    .module-title {
        font-weight: 900;
        margin: 0;
        margin-bottom: ${content_spacing};
        text-align: center;
        grid-area: title;
        grid-row-start: 1;
        grid-row: 1;


        ${theme.mediaQuerys.smUp} {
            text-align: left;
            width: auto;
            min-height: auto;
            grid-column: 1 / 3;
            /* grid-row: 1; */
        }

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: ${md_content_spacing};
            grid-column: 1;
        }
    }

    .module-image {
        grid-area: featured-image;
        grid-row: 3;
        width: 75%;
        margin: 0 auto;
        max-width: 300px;

        ${theme.mediaQuerys.smUp} {
            grid-column: 1;
            margin-bottom: -30px;
            grid-row-start: 2;
            grid-row: 3;
            margin-top: -140px;
        }

        ${theme.mediaQuerys.mdUp} {
            grid-row-start: 2;
            grid-row: 3;
            margin-top: -75%;
            max-width: 400px;
            width: 100%;
            margin-bottom: -40px;
            grid-column: 2;
        }

        ${theme.mediaQuerys.lgUp} {
            max-width: 450px;
            width: 110%;
            margin-top: -90%;
            margin-bottom: -50px;
        }
    }

    .module-text {
        grid-area: subtext;
        grid-row: 2;
        margin-bottom: ${content_spacing};
        text-align: center;

        ${theme.mediaQuerys.smUp} {
            grid-column: 2;
            grid-row: 2;
            margin-top: 30px;
            text-align: left;
        }

        ${theme.mediaQuerys.mdUp} {
            grid-column: 1;
            margin-top: 0;
            margin-bottom: ${md_content_spacing};
        }
    }

    .module-button-wrapper {
        position: relative;
        grid-area: button;
        grid-row: 4;
        padding: 0 5px;

        ${theme.mediaQuerys.smUp} {
            grid-column: 2;
            grid-row: 3;
        }


        ${theme.mediaQuerys.mdUp} {
            width: 80%;
            grid-column: 1;
        }

        ${theme.mediaQuerys.lgUp} {
            width: 70%;
        }
    }
`
import React from 'react'
import { StyledGridContentContainer } from './styles'

import { StyledModuleContainer } from '@styles/global-components/_containers';

import WrButton from '@commons/wr-button';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '@commons/wysiwyg-content';

export default function ImageOffsetWithTextContent({
    module,
}) {
    if(module) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            innerSpacing={module.enablePointer ? true : ''}
            isOverflowHidden={module.enablePointer ? true : ''}
            data-name="image-offset-with-text-content"
        >
            <StyledGridContentContainer
                type={'outer'}
            >       
                {module.title && 
                    <ModuleTitle
                        className="module-title"
                        titleFontOptions={{ mobile: 'sm', desktop: 'sm' }}
                    > 
                        {module.title}
                    </ModuleTitle>
                }

                {module.featuredImage && 
                    <WrImage 
                        className="module-image spacer-module-offset-bottom-md"
                        alt={module.featuredImage.altText}
                        imageFile={module.featuredImage.imageFile}
                    />
                }
                
                {module.text && 
                    <WysiwygContent 
                        className="module-text"
                        bodyFontOptions={{ mobile: 'md', desktop: 'sm' }}
                    >
                        {module.text}
                    </WysiwygContent>
                }

                {module.buttonLink && 
                    <WrButton 
                        buttonWrapperClass="module-button-wrapper"
                        href={module.buttonLink.url} 
                        buttonOptions={module.buttonOptions}
                        fontSize="sm"
                    >
                        {module.buttonLink.title}
                    </WrButton>
                }            
            </StyledGridContentContainer>
        </StyledModuleContainer>
    )
    return null;
}
